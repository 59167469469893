let options = {
	site: "b2consumer-mobile",
	client: "homedepotmobile",
	publishPath: "prod"
};

const site = options.site;

let getClient = function () {

	return options.client;

};


let redactEmail = function( item ) {
    if ( item.search(/[a-zA-Z0-9._%+]{1,64}@([a-zA-Z0-9]{1,63}\.){1,2}[a-zA-Z][a-zA-Z0-9]{1,62}/) > -1 ) {
		return "email address";
	} else {
		return item;
	}
};


/**
 * getValuesFromObj provides mechanism to retrieve values from object
 * @param attribute This is the attribute needed, can be dot notation for deeper values (no more than 4)
 * @param collection direct reference to array object containing single/multiple nodes w/attributes
 * @param delimiter comma delimited by default
 * @returns {string} values of all attribute values found in collection
 */
let getValuesFromObj = function ( attribute, collection, delimiter ) {

	let item, val, atrAry, attr1, attr2, attr3, attr4, cItm,
		attribList = [],
		delimit = delimiter ? delimiter : ',';

	try {
		//accept dot-notation attribute reference
		if ( attribute.indexOf( '.' ) > -1 ) {
			atrAry = attribute.split( '.' );
		}
		else {
			atrAry = [ attribute ];
		}

		for ( item in collection ) {
			cItm = collection[ item ];
			//prep delimiter to prepend values if result already has values
			delimit = delimiter ? delimiter : ',';
			if ( atrAry ) {
				attr1 = atrAry[ 0 ];
				attr2 = ( atrAry.length === 2 ? atrAry[ 1 ] : null );
				attr3 = ( atrAry.length === 3 ? atrAry[ 2 ] : null );
				attr4 = ( atrAry.length === 4 ? atrAry[ 3 ] : null );
				if ( attr4 ) {
					val = ( cItm[ attr1 ][ attr2 ][ attr3 ][ attr4 ] );
				}
				else if ( attr3 ) {
					val = ( cItm[ attr1 ][ attr2 ][ attr3 ] );
				}
				else if ( attr2 ) {
					val = ( cItm[ attr1 ][ attr2 ] );
				}
				else if ( attr1 ) {
					val = ( cItm[ attr1 ] );
				}
				if ( val ) {
					attribList.push( val );
					val = '';
				}

			}
		}
		return attribList.join( delimit );
	}
	catch ( e ) {
		console.log( '**** getAttributesFromObj failed - attribute specified likely not defined', e );
	}
};

let getPxScore = function () {
	return ( _aape.pxbs ? _aape.pxbs : '' );
}

let insertScript = function ( url, el, mode ) {
	
	el = ( typeof el !== 'undefined' ? el : 'head' );

	let newScript = document.createElement( 'script' );
	newScript.setAttribute( 'charset', 'UTF-8' );
	newScript.setAttribute( 'src', url );
	
	if ( typeof mode !== 'undefined' ) {

		newScript.setAttribute( mode, '' );

	}

	document.getElementsByTagName( el ).item( 0 ).appendChild( newScript );

};

let insertScriptWithCallback = function ( src, callback ) {

	var scriptNodes = document.getElementsByTagName( "script" ),
		i,
		firstScriptNode = scriptNodes[ 0 ];

	/* eslint-disable no-unsafe-finally */
	for ( i = 0; i < scriptNodes.length; i++ ) {

		if ( scriptNodes[ i ].src === src && scriptNodes[ i ].readyState && /loaded|complete/.test( scriptNodes[ i ].readyState ) ) {
			try {
				callback();
			}
			catch ( error ) {
				console.log( "insertScriptWithCallback callback catch error: ", error );
			}
			finally {
				return;
			}
		}

	}
	/* eslint-enable no-unsafe-finally */

	let newNode = document.createElement( "script" );
	newNode.type = "text/javascript";
	newNode.async = true;
	newNode.src = src;
	newNode.onerror = function () {
		this.addEventListener && ( this.readyState = "loaded" );
	};
	newNode.onload = newNode.onreadystatechange = function () {
		if ( !this.readyState || "complete" === this.readyState || "loaded" === this.readyState ) {

			this.onload = this.onreadystatechange = null;
			this.addEventListener && ( this.readyState = "loaded" );
			try {
				callback.call( this );
			}
			catch ( error ) {
				console.log( "insertScriptWithCallback callback catch error: ", error );
			}
		}
	};

	firstScriptNode.parentNode.insertBefore( newNode, firstScriptNode );

};


let isATC = function ( trigger ) {

	if ( ( trigger.indexOf( "add_to_cart_click*cart_add" ) > -1 ) || ( trigger.indexOf( "overlay*cart_add" ) > -1 ) || ( trigger.indexOf( "overlay*parts_services_and_hdpp_cart_add" ) > -1 ) ) {
		return true;
	}
	else {
		return false;
	}

};


let isScriptOnPage = function ( srcString ) {

	let scriptTags = Array.from( document.scripts );
	let result = scriptTags.findIndex( ({ src }) => { return src.indexOf( srcString ) > -1 });

	return ( result > -1 ? true : false );

};


/**
 * Reads the hda session storage or cookie
 * @param {string} keyName
 * @returns {*}
 */
var read = function ( keyName ) {

	var hda = "",
		returnVal = "";

	keyName = ( keyName && keyName !== "hda" ) ? keyName : "";

	try {
		hda = ( typeof ( Storage ) !== "undefined" ? sessionStorage.getItem( "hda" ) : '' );
	}
	catch ( e ) {
		console.log( "Error reading Storage: ", e );
	}

	if ( hda ) {
		hda = JSON.parse( hda );
		if ( keyName ) {
			returnVal = ( hda[ keyName ] ) ? hda[ keyName ] : "";
		}
		else {
			returnVal = hda;
		}
	}

	return returnVal;

};


/**
 * Write value to session storage or cookie as available
 * @param {string} name
 * @param {string} value
 */
var write = function ( name, value ) {
	var jsonStore = read(),
		strStore,
		timeUpdate = "";

	if ( jsonStore ) {
		jsonStore[ name ] = value;
		strStore = JSON.stringify( jsonStore );
	}
	else {
		timeUpdate = new Date();
		timeUpdate = timeUpdate ? timeUpdate.getHours() + ':' + timeUpdate.getMinutes() + ':' + timeUpdate.getSeconds() : "not defined";
		strStore = "{\"" + name + "\":\"" + value + "\",\"t\":\"" + timeUpdate + "\"}";
	}

	var storeUsed = "";

	try {
		if ( typeof ( Storage ) !== "undefined" ) {
			sessionStorage.setItem( "hda", strStore );
			storeUsed = "localStorage:" + strStore;
		}
	}
	catch ( e ) {
		console.log( "Error writing to Storage: ", e );
	}

	return storeUsed;
};

export {
	getClient,
	getPxScore,
	getValuesFromObj,
	insertScript,
	insertScriptWithCallback,
	isATC,
	isScriptOnPage,
	read,
	redactEmail,
	site,
	write
};
